<template>
    <div class="menu-wrap">
        <el-menu :defaultActive="navIndex" mode="horizontal" class="el-menu-vertical-demo" active-text-color="#fd5621" :router="true">
            <el-menu-item :index="m.id" :key="m.id" v-for="m in navList" :route="{path:'/admin_rz', query: {id:m.id}}">{{m.name}}</el-menu-item>
        </el-menu>
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        data() {
            return {
                navIndex: "2",
                navList: [{
                        name: "经历",
                        id: "2",
                    },
                    {
                        name: "擅长",
                        id: "1",
                    },
                ],
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.checkPath(this.$route);
            if (!this.s_getUser) {
                this._home();
            }
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            // ...mapMutations([])
            checkPath(t) {
                let q = t.query;
                if (q && q.id) {
                    this.navIndex = q.id;
                }
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            $route(to, from) {
                // 路径判断
                this.checkPath(to);
            },
            s_getUser(n) {
                if (!n) {
                    this._home();
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .menu-wrap {
        display: block;

        .iconfont {
            font-size: 20px;
            margin-right: 10px;
        }

        .content {
            min-height: 100vh;
            background: #fff;
            padding: 10px;
        }
    }

    /deep/.el-menu-item:focus,
    /deep/.el-menu-item:hover {
        // background-color: #ebf7ff;
        background-color: transparent;
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .menu-wrap {
            .iconfont {
                margin-right: 0;
            }
        }

        .el-menu-vertical-demo {
            width: auto;

            span {
                display: none;
            }
        }
    }
</style>